import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css'
import 'element-plus/dist/index.css'

const app = createApp(App)
//图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus)
    .use(router)
    .mount('#app');
