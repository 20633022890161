<template>
  <h1>网上预约</h1>
  <el-form
           ref="formRef"
           :model="form"
           :rules="formRules"
  >
    <el-form-item label="姓名:" prop="userName">
      <el-input v-model="form.userName"
                placeholder="请问，如何称呼您！"
      />
    </el-form-item>
    <el-form-item label="联系电话:" prop="userPhone">
      <el-input
          v-model="form.userPhone"
          maxlength="11"
          show-word-limit
          placeholder="请填写有效手机号！方便联系！" clearable
      />
    </el-form-item>
    <el-form-item label="培训人年龄:" prop="userAge">
      <el-input-number size="large" v-model="form.userAge" :precision="0" :min="0" :max="150"/>
    </el-form-item>
    <el-form-item label="培训人左眼度数:"  prop="leftDegree">
      <el-input-number size="large" v-model="form.leftDegree" :min="0" :precision="0"/>
    </el-form-item>
    <el-form-item label="培训人右眼度数:" prop="rightDegree">
      <el-input-number size="large" v-model="form.rightDegree" :min="0" :precision="0"/>
    </el-form-item>
    <el-form-item label="培训人左眼闪光:" prop="leftFlash">
      <el-input-number size="large" v-model="form.leftFlash" :min="0" :precision="0"/>
    </el-form-item>
    <el-form-item label="培训人右眼闪光:" prop="rightFlash">
      <el-input-number size="large" v-model="form.rightFlash" :min="0" :precision="0"/>
    </el-form-item>
    <el-form-item class="centered">
       <el-button type="primary" @click="onSubmit">完成</el-button>
    </el-form-item>
    <div v-show="isVisible">
      <slide-verify
          ref="block"
          :slider-text="text"
          :accuracy="accuracy"
          @again="onAgain"
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh"
      ></slide-verify>
      <div>{{ msg }}</div>
    </div>
  </el-form>
</template>

<script setup>
import {reactive, ref} from "vue";
import SlideVerify from "vue3-slide-verify";
import "vue3-slide-verify/dist/style.css";
import axios from "axios";
import dayjs from 'dayjs';
import {useRouter} from 'vue-router';
const router = useRouter();

axios.defaults.timeout = 5000

const msg = ref("");
const block = ref();
const text = ref("滑动对齐确认信息");
const accuracy = ref(1);
const isVisible = ref(false);
const formRef = ref();

const form = reactive({
  userName: '',
  userPhone:'',
  leftDegree: '',
  rightDegree: '',
  leftFlash: '',
  rightFlash: '',
  userAge: '',
});

const onSubmit = () => {
  formRef.value?.validate().then(() => {
    // 表单验证通过时的处理逻辑
    isVisible.value = true;
  }).catch(() => {
    // 表单验证不通过时的处理逻辑
  });

};


const onAgain = () => {
  // msg.value = "检测到非人为操作的哦！ try again";
  msg.value = "";
  block.value.refresh();
};

const onSuccess = () => {
  // msg.value = `login success, 耗时${(times / 1000).toFixed(1)}s`;

  axios.post(
      // "http://114.67.246.76:8888/api/user/addUser",
      "https://wcback.gongyitech.com/api/user/addUser",
      {
        "userName": form.userName,
        "userPhone": form.userPhone,
        "userAge": form.userAge,
        "leftDegree": form.leftDegree,
        "rightDegree": form.rightDegree,
        "leftFlash": form.leftFlash,
        "rightFlash": form.rightFlash,
        "addTime": dayjs().format('YYYY-MM-DD HH:mm:ss')
      },
      {
        'Content-Type': 'application/json', // 设置请求头，根据你的需求设置合适的Content-Type
      }
  )
      .then((res) => {
        if (res.status == 200) {
          router.push({path:'/welcomeView', query: { userName: form.userName, userPhone: form.userPhone }});
        }
      }).catch(() => {
          msg.value = "注册失败，请直接电话联系我们！";
      });

};

const onFail = () => {


};

const onRefresh = () => {
  // msg.value = "点击了刷新小图标";
  msg.value = "";
};

const checkPhone = async(rule, value, callback) => {
  isVisible.value = false;
  if (value.length < 11) {
    return callback(new Error('请输入手机号'));
  } else if (value.length >= 11 && !/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(value)) {
    return callback(new Error('手机号格式错误'));
  } else {
    try {
      const res = await axios({
        method: 'post',
        url: "https://wcback.gongyitech.com/api/user/getUserInfoByPhone",
        // url: "http://114.67.246.76:8888/api/user/getUserInfoByPhone",
        data: {"userPhone": form.userPhone},

      });
      if (res.status == 200 && res.data.length > 0) {
        return callback(new Error('该手机号已注册！'));
      }
    } catch (error) {
      callback(error, null);
    }
  }
};

const formRules = reactive({
  userName: [
    {required: true, message: '请输入姓名', trigger: 'blur'},
  ],
  userPhone: [
    {required: true, trigger: 'blur', validator: checkPhone}
  ],
  leftDegree: [
    {required: true, message: '请输入左眼近视度数', trigger: 'change'},
  ],
  rightDegree: [
    {required: true, message: '请输入右眼近视度数', trigger: 'change'},
  ],
  leftFlash: [
    {required: true, message: '请输入左眼闪光度数', trigger: 'change'},
  ],
  rightFlash: [
    {required: true, message: '请输入右眼闪光度数', trigger: 'change'},
  ],
  userAge: [
    {required: true, message: '请输入年龄', trigger: 'change'},
  ]
});

</script>
<style>
.centered {
  position: center;
}
</style>
