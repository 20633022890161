<template>
  <el-table
      :data="userList"
      :default-sort="{ prop: 'add_time', order: 'descending' }"
  >
    <el-table-column prop="user_name" label="姓名" />
    <el-table-column prop="user_phone" label="手机号"/>
    <el-table-column prop="user_age" label="年龄" />
    <el-table-column prop="left_degree" label="左眼近视" />
    <el-table-column prop="right_degree" label="右眼近视" />
    <el-table-column prop="left_flash" label="左眼闪光" />
    <el-table-column prop="right_flash" label="右眼闪光"/>
    <el-table-column prop="add_time" label="时间" sortable />
  </el-table>

</template>
<!--"userName": form.userName,-->
<!--"userPhone": form.userPhone,-->
<!--"userAge": form.userAge,-->
<!--"leftDegree": form.leftDegree,-->
<!--"rightDegree": form.rightDegree,-->
<!--"leftFlash": form.leftFlash,-->
<!--"rightFlash": form.rightFlash,-->
<!--"addTime": dayjs().format('YYYY-MM-DD HH:mm:ss')-->
<script setup>
import axios from "axios";
import {ref,onMounted} from "vue";
axios.defaults.timeout = 5000
import dayjs from 'dayjs';

const userList = ref();
onMounted(() => {
  // axios.post("http://114.67.246.76:8888/api/user/getAllUser").then((res) => {
  axios.post("https://wcback.gongyitech.com/api/user/getAllUser").then((res) => {
    userList.value = '';
    if (res.status == 200 && res.data.length > 0) {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].add_time = dayjs(res.data[i].add_time).format('YYYY-MM-DD HH:mm:ss');
      }
      userList.value = res.data;
    }
  });
});



</script>

<style scoped>

</style>