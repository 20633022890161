import { createRouter, createWebHistory } from 'vue-router';
import UserRegisterView from "@/views/UserRegisterView";
import welcomeView from "@/views/welcomeView";
import ShowAllUserView from "@/views/ShowAllUserView";
const routes = [
    {
        path: '/',
        name: 'UserRegisterView',
        component: UserRegisterView,
        meta: {
            title: '信息填写'
        }
    },
    {
        path: '/welcomeView',
        name: 'welcomeView',
        component: welcomeView,
        meta: {
            title: '预约成功'
        }
    },
    {
        path: '/ShowAllUserView',
        name: 'ShowAllUserView',
        component: ShowAllUserView,
        meta: {
            title: '所有用户'
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
// 设置导航守卫，在每个页面跳转时动态修改浏览器顶部的名称
router.beforeEach((to, from, next) => {
    // 替换成你想要显示的页面名称
    const pageTitle = to.meta.title || 'Your Website Name';

    // 使用DOM操作修改浏览器顶部的名称
    document.title = pageTitle;

    next();
});
export default router;
