<template>
  <div>
    <h2>预约成功！请退出！</h2>
    <p>用户名：{{ router.query.userName }}</p>
    <p>手机号：{{ router.query.userPhone }}</p>
<!--    <el-button type="primary" @click="onSubmit">确认退出</el-button>-->
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router';
const router = useRoute();
// const onSubmit = () => {
//   window.close();
// };
</script>

<style scoped>

</style>